<template>

  <router-link to="/men" style="display:block;margin:120px auto 48px;width:100%;max-width:800px;">&lt; Back to your Real Man Army list</router-link>
<div id="onemantainer" v-if="man">
  <div id="man">
    <ManPfp :man="man" />
    <ul id="attrlst">
      <li><strong>Manpower:</strong> {{man.manpower}}</li>
      <li><strong>Quality:</strong> {{man.quality}}</li>
      <li><strong>Fights:</strong> {{man.fights}}</li>
      <li><strong>Wins:</strong> {{man.wins}} ({{ man.fights > 0 ? ((man.wins/man.fights)*100.0).toFixed(1) : "0.0" }}%)</li>
      <li><strong>Height:</strong> <span v-html="man.height"></span></li>
      <li><strong>Weight:</strong> <span v-html="man.weight"></span></li>
      <li><strong>Pet bonus: </strong>
        <span v-if="man.pet"> +{{man.pet.power}} from {{man.pet.name}} (#{{man.pet.id}})</span>
        <span v-else>None</span>
      </li>
      <li><strong>Weapon: </strong>
        <span v-if="man.weapon"> +{{man.weapon.power}} from {{man.weapon.name}} (#{{man.weapon.id}})</span>
        <span v-else>None</span></li>
      <li><strong>Injuries:</strong> {{man["injuries"].length === 0 ? "None" : man["injuries"].join(", ")}}</li>
    </ul>
  </div>
  <div id="actions">
    <h1>{{man.name}}</h1>
    <h2>(#{{man.id}})</h2>
    <p v-if="man.owner" style="display: block; width: 100%; text-align: center;">Owned by {{man.owner}}</p>

    <button type="button" class="btn btn-oneman" @click="nameModal = true;$emit('blur')">Rename this Real Man</button>
    <button type="button" class="btn btn-oneman" @click="ownerModal = true;$emit('blur')">Add/edit his owner's name</button>
    <button type="button" class="btn btn-oneman" @click="getPetList">Let this Real Man adopt a Battlepet</button>
    <button type="button" class="btn btn-oneman" @click="getWeaponsList">Quick, somebody get him a weapon!</button>
    <button type="button" class="btn btn-oneman" @click="healModal = true;$emit('blur')">Heal this Real Man</button>
    <button type="button" class="btn btn-oneman" @click="hideModal = true;$emit('blur')">
      <span v-if="man.hiding">Come out of hiding</span>
      <span v-else>Put this man in to hiding</span>
    </button>
    <button type="button" class="btn btn-oneman" @click="journalModal = true;$emit('blur')">
      <span>Read my history</span>
    </button>
    <button type="button" class="btn btn-fightman" @click="fight('Random')" :class="{'disabled': thinking}" :disabled="thinking">
        <span v-if="thinking">
          <div class="spinner-container">
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
          </div>
        </span>
      <span v-else>Fight another random Real Man</span>
    </button>
    <button type="button" class="btn btn-fightman" @click="fightModal = true;$emit('blur')">Choose another Real Man to fight</button>
    <button type="button" class="btn btn-dungeon" @click="$router.push('/dungeon')">ENTER THE DUNGEON</button>


  </div>
</div>

  <div class="modal" v-if="nameModal">
    <div class="modal-close">
      <span style="cursor:pointer;" @click="nameModal = false;$emit('blur')">
        &times;
      </span>
    </div>
    <div class="modal-content">
      <p>What should we call this Real Man?</p>
      <input type="text" v-model="newName" placeholder="New name" style="width: 250px;">
      <button type="button" class="btn btn-ok" @click="changeName" :class="{'disabled': thinking}" :disabled="thinking">
        <span v-if="thinking">
          <div class="spinner-container">
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
          </div>
        </span>
        <span v-else>Change name</span>
      </button>
    </div>
  </div>

  <div class="modal" v-if="ownerModal">
    <div class="modal-close">
        <span style="cursor:pointer;" @click="ownerModal = false;$emit('blur')">
          &times;
        </span>
    </div>
    <div class="modal-content">
      <p>And what is your name, great owner?</p>
      <input type="text" v-model="ownerName" placeholder="New owner name" style="width: 250px;">
      <button type="button" class="btn btn-ok" @click="changeOwner" :class="{'disabled': thinking}" :disabled="thinking">
        <span v-if="thinking">
          <div class="spinner-container">
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
          </div>
        </span>
        <span v-else>Change owner name</span>
      </button>
    </div>
  </div>

  <div class="modal" v-if="petModal">
    <div class="modal-close">
        <span style="cursor:pointer;" @click="petModal = false;$emit('blur')">
          &times;
        </span>
    </div>
    <div class="modal-content">
      <p>Choose a pet for him</p>
      <div class="contentbox">
        <div v-if="petsLoading">
          <p class="flash">Loading your zoo...</p>
        </div>
        <div v-if="!petsLoading && petsList.length === 0">
          <p>You have no pets!</p>
          <p><a href="https://market.x.immutable.com/assets?collection=0xacb3c6a43d15b907e8433077b6d38ae40936fe2c&filters[tribe][0]=wild" target="_blank">Click here to buy some</a>.</p>
        </div>
        <div v-else>
          <div v-for="(pet, i) in petsList" style="width:100%;padding:0;margin:0;" :key="`p${i}`">
            <button class="btn btn-scroller" @click="addPet(pet.token_id)" :class="{'disabled': thinking}" :disabled="thinking">
              {{pet.newname}}<br>
              <small>(#{{pet.token_id}})</small><br>
              Power bonus: <strong>+{{pet.manpower}}</strong>
            </button>
            <!-- TODO Delete button -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" v-if="weaponModal">
    <div class="modal-close">
        <span style="cursor:pointer;" @click="weaponModal = false;$emit('blur')">
          &times;
        </span>
    </div>
    <div class="modal-content">
      <p>What's his weapon?</p>
      <div class="contentbox">
      <div v-if="weaponsLoading">
        <p class="flash">Loading your arsenal...</p>
      </div>
      <div v-if="!weaponsLoading && weaponsList.length === 0">
        <p>You have no weapons!</p>
        <p><a href="https://tokentrove.com/collection/GodsUnchainedCards" target="_blank">Click here to buy some</a>.</p>
      </div>
      <div v-else>
          <div v-for="(weapon, i) in weaponsList" style="width:100%;padding:0;margin:0;" :key="`w${i}`"  :class="{'disabled': thinking}" :disabled="thinking">
            <button class="btn btn-scroller" @click="addWeapon(weapon.token_id)">
              {{weapon.newname}}<br>
              <small>(#{{weapon.token_id}})</small><br>
              Power bonus: <strong>+{{weapon.manpower}}</strong>
            </button>
            <!-- TODO Delete button -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" v-if="healModal">
    <div class="modal-close">
        <span style="cursor:pointer;" @click="healModal = false;$emit('blur')">
          &times;
        </span>
      <div class="modal-content">
        <p>Returning to health...</p>
        <div style="font-size: 9pt;text-align: justify; padding: 0 8px 0 16px;">
          <!-- TODO Add links -->
          <p>Healing powers haven't been activated yet, but there will be three ways to heal your Real Men...</p>
          <ol>
            <li>Destroying one Holy Writ in the Real Man campfire will remove all injuries from all of your men and revive any poorly ones to their starting health.</li>
            <li>You can spend Real Man tokens to remove individual injuries and give health boosts. Real Men tokens don't exist yet.</li>
            <li>If you hold a physical Real Man Fight Club VIP card you get unlimited free heals for life. <!-- TODO https://github.com/nimiq/qr-scanner --></li>
          </ol>
          <p>Watch this space.</p>
        </div>
        </div>
    </div>
  </div>

  <div class="modal" v-if="fightModal">
    <div class="modal-close">
      <span style="cursor:pointer;" @click="fightModal = false;$emit('blur')">
        &times;
      </span>
    </div>
    <div class="modal-content">
      <p>Who would you like to fight?</p>
      <input type="text" placeholder="Enter man ID number" style="width: 250px;" v-model="defender">
      <button type="button" class="btn btn-ok" @click="fight(defender)" :class="{'disabled': thinking}" :disabled="thinking">
        <span v-if="thinking">
          <div class="spinner-container">
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
          </div>
        </span>
        <span v-else>Fight!</span>
      </button>
    </div>
  </div>

  <div class="modal" v-if="hideModal">
    <div class="modal-close">
      <span style="cursor:pointer;" @click="hideModal = false;$emit('blur')">
        &times;
      </span>
    </div>
    <div class="modal-content" v-if="man.hiding">
      <p>Come out of hiding?</p>
      <p><small>Welcome back to the world, big man. You smell awful.</small></p>
      <button type="button" class="btn btn-ok" @click="toggleHiding">Stop hiding</button>
    </div>
    <div class="modal-content" v-else>
      <p>Go into hiding?</p>
      <p><small>If you go into hiding you'll be safe from attack by other men.
        However, you'll lose {{$settings.hidingCost}} manpower when you go into hiding, and {{$settings.hidingDaily}} per day for being a coward.</small></p>
      <button type="button" class="btn btn-ok" @click="toggleHiding" :class="{'disabled': thinking}" :disabled="thinking">
        <span v-if="thinking">
          <div class="spinner-container">
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
            <div class="block"></div>
          </div>
        </span>
        <span v-else>Go into hiding</span>
      </button>
    </div>
  </div>

  <div class="modal" v-if="journalModal">
    <div class="modal-close">
        <span style="cursor:pointer;" @click="journalModal = false;$emit('blur')">
          &times;
        </span>
    </div>
    <div class="modal-content">
      <p>My story...</p>
      <div class="contentbox">
        <div v-if="log.length === 0">
          <p>You have achieved nothing yet.</p>
        </div>
        <div v-else>
          <p v-for="entry in log" :key="entry['ref']" style="padding:0 12px">
            <em>{{entry.data.dt}}</em><br> {{entry.data.note}}
          </p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {manStore, fightStore} from "@/store";
import {ImmutableXClient} from "@imtbl/imx-sdk";
import {ethers} from "ethers";
import faunadb from "faunadb";
const q = faunadb.query
import ManPfp from "@/components/ManPfp";

export default {
  name: "OneMan",
  props: ['address', 'db'],
  emits: ['blur', 'toast', 'play'],
  components: {ManPfp},
  computed:{
    man(){
      return manStore.state.men[this.$route.params.id]
    }
  },
  data() {
    return {
      defender:null,
      weaponModal: false,
      weaponsLoading: false,
      nameModal: false,
      petModal: false,
      petsLoading: false,
      fightModal: false,
      healModal: false,
      hideModal: false,
      ownerModal: false,
      newName: "",
      ownerName: "",
      noToggle:false,
      weaponsList: [],
      needWeapons: true,
      petsList: [],
      needPets: true,
      thinking: false,
      log:[],
      journalModal: false
    }
  },
  methods:{
    async fight(opponent){
      this.thinking = true
      try{
        let resp = await this.rmSigner(`Man #${this.$route.params.id}: Fight: ${opponent.replace("#","").trim()}`)
        if(resp.status === 200){
          manStore.updateMan(this.$route.params.id, resp.data.data)
          let stamp = Date.now().toString()
          await fightStore.addFight(stamp, resp.data)
          if(this.fightModal){this.$emit('blur')}
          await this.$router.push("/fight/" + stamp)
        } else {
          this.$emit('toast', "Error initiating fight: " + resp.data.error)
          this.thinking = false
        }
      } catch(e){
        this.$emit('toast', "Error initiating fight: Cannot connect to server.")
        this.thinking = false
      }
    },
    async changeName(){
      this.thinking = true
      try{
        let resp = await this.rmSigner(`Man #${this.$route.params.id}: Change name: ${this.newName}`)
        if(resp.status === 200){
          manStore.updateMan(this.$route.params.id, resp.data)
          this.nameModal = false
          this.newName = ""
          this.$emit('blur')
          this.thinking = false
        } else {
          this.$emit('toast', "Error changing name: " + resp.data.error)
          this.thinking = false
        }
      } catch(e){
        this.$emit('toast', "Error changing name: Cannot connect to server.")
        this.thinking = false
      }
    },
    async changeOwner(){
      this.thinking = true
      try{
        let resp = await this.rmSigner(`Man #${this.$route.params.id}: Change owner name: ${this.ownerName}`)
        if(resp.status === 200){
          manStore.updateMan(this.$route.params.id, resp.data)
          this.ownerModal = false
          this.ownerName = ""
          this.$emit('blur')
          this.thinking = false
        } else {
          this.$emit('toast', "Error changing owners name: " + resp.data.error)
          this.thinking = false
        }
      } catch(e){
        this.$emit('toast', "Error changing owners name: Cannot connect to server.")
        this.thinking = false
      }
    },
    async toggleHiding(){
      this.thinking = true
      try{
        let resp = await this.rmSigner(`Man #${this.$route.params.id}: Hiding: ${this.man.hiding ? "no" : "yes"}`)
        if(resp.status === 200){
          manStore.updateMan(this.$route.params.id, resp.data)
          this.ownerModal = false
          this.ownerName = ""
          this.thinking = false
        } else {
          this.$emit('toast', "Error changing hiding status: " + resp.data.error)
          this.thinking = false
        }
      } catch(e){
        this.$emit('toast', "Error changing hiding status: Cannot connect to server.")
        this.thinking = false
      }
    },
    async getWeaponsList(){
      const self=this
      self.weaponsLoading = true
      self.$emit('blur')
      self.weaponModal = true;
      if(self.needWeapons) {
        let ret = await fetch("https://rmfcpvt0x5e89.b-cdn.net/weaponsProtos.json")
        if (ret.status !== 200) {
          self.$emit('toast', "Error getting weapons list.")
        } else {
          let weaponslist = await ret.json()

          const client = await ImmutableXClient.build({publicApiUrl: 'https://api.x.immutable.com/v1'});
          let weaponArray = []
          let assetRequest
          let assetCursor

          do {
            try{
              assetRequest = await client.getAssets({
                user: self.address,
                status: 'imx',
                collection: '0xacb3c6a43d15b907e8433077b6d38ae40936fe2c',
                metadata: JSON.stringify({proto: weaponslist}),
                cursor: assetCursor
              })
            } catch (err) {
              console.error(err)
              self.$emit('toast', "Error getting your weapons list.")
            }
            weaponArray = weaponArray.concat(assetRequest.result)
            assetCursor = assetRequest.cursor
          } while (assetCursor)

          await weaponArray.forEach(w => {
            w["newname"] = w.metadata.quality + " " + w.name
            w["manpower"] = w.metadata.attack * self.$settings.weaponpetMultiplier[w.metadata.quality]
          })

          weaponArray = weaponArray.sort((a, b) => parseFloat(a.newname) - parseFloat(b.newname));
          weaponArray = weaponArray.sort((a, b) => parseFloat(b.manpower) - parseFloat(a.manpower));
          self.weaponsList = weaponArray
          self.needWeapons = false
        }
      }
      self.weaponsLoading = false
    },
    async addWeapon(weaponId){
      this.thinking = true
      try{
      let resp = await this.rmSigner(`Man #${this.$route.params.id}: Equip weapon: ${weaponId}`)
        if(resp.status === 200){
          manStore.updateMan(this.$route.params.id, resp.data)
          this.weaponModal = false
          this.$emit('blur')
          this.thinking = false
        } else {
          this.$emit('toast', "Error adding weapon: " + resp.data.error)
          this.thinking = false
        }
      } catch(e){
        this.$emit('toast', "Error adding weapon: Cannot connect to server.")
        this.thinking = false
      }
    },
    async getPetList(){
      const self=this
      self.petsLoading = true
      self.$emit('blur')
      self.petModal = true;
      if(self.needPets){
        const client = await ImmutableXClient.build({publicApiUrl: 'https://api.x.immutable.com/v1'});
        let petArray = []
        let assetRequest
        let assetCursor

        do {
          try{
            assetRequest = await client.getAssets({
              user: self.address,
              status: 'imx',
              collection: '0xacb3c6a43d15b907e8433077b6d38ae40936fe2c',
              metadata: JSON.stringify({"tribe": ["wild"]}),
              cursor: assetCursor
            })
          } catch (err) {
            console.error(err)
            self.$emit('toast', "Error getting your pets list.")
          }
          petArray = petArray.concat(assetRequest.result)
          assetCursor = assetRequest.cursor
        } while (assetCursor)

        await petArray.forEach(w => {
          w["newname"] = w.metadata.quality + " " + w.name
          w["manpower"] = w.metadata.attack * self.$settings.weaponpetMultiplier[w.metadata.quality]
        })

        petArray = petArray.sort((a, b) => parseFloat(a.newname) - parseFloat(b.newname));
        petArray = petArray.sort((a, b) => parseFloat(b.manpower) - parseFloat(a.manpower));
        self.petsList = petArray
        self.needPets = false
      }
      self.petsLoading = false
    },
    async addPet(petId){
      this.thinking = true
      try {
        let resp = await this.rmSigner(`Man #${this.$route.params.id}: Adopt a pet: ${petId}`)
        if(resp.status === 200){
          manStore.updateMan(this.$route.params.id, resp.data)
          this.petModal = false
          this.$emit('blur')
          this.thinking = false
        } else {
          this.$emit('toast', "Error adding pet: " + resp.data.error)
          this.thinking = false
        }
      } catch(e){
        this.$emit('toast', "Error adding weapon: Cannot connect to server.")
        this.thinking = false
      }
    },
    async rmSigner(message) {
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      try{
        await window.ethereum.request({ method: 'eth_requestAccounts' });
      }catch(e){
        this.$emit('toast', "Error connecting to wallet")
      }

      // TODO check right network FOR PURCHASE/SWAPS
      const signer = provider.getSigner();
      let sig
      try{
        sig = await signer.signMessage(message)
      }catch(e){
        this.$emit('toast', "Error authorizing command")
      }

      const options = {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify({
          msg: message,
          sig: sig,
          man: this.$route.params.id
        })};
      let resp = await fetch(this.$backend, options)
      return {status: resp.status, data: await resp.json()}
    }
  },
  async mounted(){
    if(!this.address){
      this.$router.replace("/")
    }
    // eslint-disable-next-line no-prototype-builtins
    if(!manStore.state.men[this.$route.params.id]){
      //this.$emit('toast', "No man found with that ID!")
      await this.$router.replace("/men")
    } else {
      let faunaRes2 = await this.db.query(
          q.Map(
              q.Paginate(
                  q.Match(q.Index('log_by_man'), this.$route.params.id)
              ),
              q.Lambda(x => q.Get(x))
          )
      )
      this.log = faunaRes2.data
    }
    this.$emit('play')
  }
}
</script>

<style scoped>

#attrlst li{
  padding-bottom: 8px;
}

.modal{
  z-index: 10000;
  width: 300px;
  position: fixed;
  border-radius: 5px;
  background: var(--man3);
  border: 2px solid var(--black);
  color: var(--text);
  left: calc(50% - 150px);
  top: calc(50% - 200px);
  display: block;
  max-height: 400px;
  overflow-y: hidden;
}

.modal-close{
  width:100%;
  text-align: right;
  font-size:16pt;
  padding:8px 12px 0 0;
  box-sizing: border-box;
  color:var(--text);
}

.modal-content{
  position: relative;
  padding: 0 8px 8px;
  top: -24px;
  text-align: center;
  font-size: 14pt;
  box-sizing: border-box;
}

.contentbox{
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #fff;
  padding:6px 0;
  border-radius: 5px;
  font-size: 10pt;
  color: #000;
  width: 280px;
  margin: 0 auto;
}

.btn-scroller{
  display: block;
  width: 250px;
  box-sizing: border-box;
  background: var(--man2);
  color: var(--text);
  margin: 8px 12px;
  font-size: 10pt;
}

.btn-scroller:hover{
  background: var(--man1);
}

.btn-ok{
  display: block;
  width: 250px;
  min-height: 41px;
  margin: 8px auto 0;
  box-sizing: border-box;
  background: var(--man1);
  color: var(--text);
  font-size: 10pt;
}

.btn-ok:hover{
  background: var(--man2);
}


#onemantainer{
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  margin: 0 auto 128px;
}
@media all and (max-width: 750px) {
  #onemantainer{
    flex-wrap: wrap;
  }
}
#man{
  box-sizing: border-box;
  padding: 0 24px;
  max-width: 400px;
}
#actions{
  box-sizing: border-box;
  max-width: 400px;
}

.btn-oneman{
  display: block;
  width: 300px;
  box-sizing: border-box;
  background: var(--man1);
  color: var(--text);
  margin: 8px 12px;
}

.btn-oneman:hover{
  background: var(--man3);
}

.btn-fightman{
  display: block;
  width: 300px;
  box-sizing: border-box;
  background: var(--man2);
  color: var(--text);
  margin: 8px 12px;
}

.btn-fightman:hover{
  background: var(--man3);
}

.btn-dungeon{
  display: block;
  width: 300px;
  box-sizing: border-box;
  background: #000;
  border: 5px solid var(--man2);
  color: var(--text);
  margin: 8px 12px;
  font-weight: bold;
  font-size: 1.5em;
  transition: color 0.15s ease-in-out;
}

.btn-dungeon:hover{
  color: #f00;
}

.disabled{
  cursor: not-allowed;
  background: #777 !important;
}
.disabled:hover{
  background: #777 !important;
}
.spinner-container {
  width: 25px;
  margin: 0 auto;
  /*margin-top: calc(100vh / 2 - 50px);*/
}

.block {
  position: relative;
  box-sizing: border-box;
  float: left;
  margin: 0 2px 2px 0;
  width: 5px;
  height: 5px;
  border-radius: 3px;
  background: #FFF;
}

.block:nth-child(3n+1) { animation: wave 1s ease .0s infinite; }
.block:nth-child(3n+2) { animation: wave 1s ease .2s infinite; }
.block:nth-child(3n+3) { animation: wave 1s ease .4s infinite; }

@keyframes wave {
  0%   { top: 0;     opacity: .8; }
  50%  { top: 5px;  opacity: .2; }
  100% { top: 0;     opacity: .5; }
}

</style>