<template>
<div id="fight-container">
  <div id="fight-box">
    <div ref="fightbox">
      <div ref="during">
        <div class="overlay-text left">
          <table>
            <tr>
              <td colspan="2" style="text-align: center">
                <h2>{{fight.names[0]}}</h2>
              </td>
            </tr>
            <tr>
              <td style="text-align: right">
                Manpower:
              </td>
              <td :class="{'hide': !unhid[0], 'grower':unhid[0]}">
                {{fight.powers[0]}}
              </td>
            </tr>
            <tr>
              <td style="text-align: right">
                Pet:
              </td>
              <td :class="{'hide': !unhid[1], 'grower':unhid[1]}">
                +{{fight.boosts[0].pet}}
              </td>
            </tr>
            <tr>
              <td style="text-align: right">
                Weapon:
              </td>
              <td :class="{'hide': !unhid[2], 'grower':unhid[2]}">
                +{{fight.boosts[0].wpn}}
              </td>
            </tr>
            <tr>
              <td style="text-align: right">
                Injuries:
              </td>
              <td :class="{'hide': !unhid[3], 'grower':unhid[3]}">
                -{{fight.boosts[0].inj}}
              </td>
            </tr>
            <tr>
              <td style="text-align: right">
                Win chance:
              </td>
              <td :class="{'hide': !unhid[4], 'grower':unhid[4]}">
                {{(fight.pct*100.0).toFixed(0)}}%
              </td>
            </tr>
          </table>
        </div>
        <div class="overlay-text right">
          <table style="float: right;">
            <tr>
              <td colspan="2" style="text-align: center">
                <h2>{{fight.names[1]}}</h2>
              </td>
            </tr>
            <tr>
              <td style="text-align: right">
                Manpower:
              </td>
              <td  :class="{'hide': !unhid[5], 'grower':unhid[5]}">
                {{fight.powers[1]}}
              </td>
            </tr>
            <tr>
              <td style="text-align: right">
                Pet:
              </td>
              <td  :class="{'hide': !unhid[6], 'grower':unhid[6]}">
                +{{fight.boosts[1].pet}}
              </td>
            </tr>
            <tr>
              <td style="text-align: right">
                Weapon:
              </td>
              <td  :class="{'hide': !unhid[7], 'grower':unhid[7]}">
                +{{fight.boosts[1].wpn}}
              </td>
            </tr>
            <tr>
              <td style="text-align: right">
                Injuries:
              </td>
              <td  :class="{'hide': !unhid[8], 'grower':unhid[8]}">
                -{{fight.boosts[1].inj}}
              </td>
            </tr>
            <tr>
              <td style="text-align: right">
                Win chance:
              </td>
              <td  :class="{'hide': !unhid[9], 'grower':unhid[9]}">
                {{((1-fight.pct)*100.0).toFixed(0)}}%
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div ref="after" class="hide overlay-text" style="z-index:9999; width:100%;text-align: center;padding: 10% 12px 0;">
        <p v-if="fight.outcome === 'W'">Congratulations! Your man {{fight.names[0]}} won against {{fight.names[1]}}, increasing his manpower by +{{fight.deltas[0]}}. </p>
        <p v-else>Sadly, {{fight.names[0]}} was defeated by {{fight.names[1]}}, and his manpower has now reduced by -{{fight.deltas[0]}}. </p>
        <p v-if="fight.injury">Unfortunately, during the fight {{fight.names[0]}}  sustained an injury, specifically {{fight.injury}}.</p>
        <button @click="$router.push('/myman/'+fight.data.id)" class="btn" style="width:100%;max-width:400px;">Back</button>
      </div>

      <img src="/img/border.png" id="border">

      <video width="1200" height="600" ref="fightvid" @canplay="startFight()" @ended="finished()">
        <source src="/media/vidw.mp4" type="video/mp4" v-if="fight.outcome==='W'">
        <source src="/media/vidl.mp4" type="video/mp4" v-else>
        Your browser does not support the video tag.
      </video>
      <!-- TODO put real data in these mobile friendly bits >
      <div-- class="info-below">
        <table>
          <tr>
            <td colspan="2" style="text-align: center">
              <h2>{{fight.names[0]}}</h2>
            </td>
          </tr>
          <tr>
            <td style="text-align: right">
              Manpower:
            </td>
            <td ref="amp" class="hide">
              {{fight.powers[0]}}
            </td>
          </tr>
          <tr>
            <td style="text-align: right">
              Pet:
            </td>
            <td ref="apet" class="hide">
              +{{fight.boosts[0].pet}}
            </td>
          </tr>
          <tr>
            <td style="text-align: right">
              Weapon:
            </td>
            <td ref="awpn" class="hide">
              +{{fight.boosts[0].wpn}}
            </td>
          </tr>
          <tr>
            <td style="text-align: right">
              Injuries:
            </td>
            <td ref="ainj" class="hide">
              -{{fight.boosts[0].inj}}
            </td>
          </tr>
          <tr>
            <td style="text-align: right">
              Win chance:
            </td>
            <td ref="awin" class="hide">
              {{(fight.pct*100.0).toFixed(0)}}%
            </td>
          </tr>
        </table>

        <table style="float: right;">
          <tr>
            <td colspan="2" style="text-align: center">
              <h2>{{fight.names[1]}}</h2>
            </td>
          </tr>
          <tr>
            <td style="text-align: right">
              Manpower:
            </td>
            <td ref="bmp" class="hide">
              {{fight.powers[1]}}
            </td>
          </tr>
          <tr>
            <td style="text-align: right">
              Pet:
            </td>
            <td ref="bpet" class="hide">
              +{{fight.boosts[1].pet}}
            </td>
          </tr>
          <tr>
            <td style="text-align: right">
              Weapon:
            </td>
            <td ref="bwpn" class="hide">
              +{{fight.boosts[1].wpn}}
            </td>
          </tr>
          <tr>
            <td style="text-align: right">
              Injuries:
            </td>
            <td ref="binj" class="hide">
              -{{fight.boosts[1].inj}}
            </td>
          </tr>
          <tr>
            <td style="text-align: right">
              Win chance:
            </td>
            <td ref="bwin" class="hide">
              {{((1-fight.pct)*100.0).toFixed(0)}}%
            </td>
          </tr>
        </table>
      </div-->
    </div>
  </div>
</div>
</template>

<script>
import {fightStore} from "@/store";
export default {
  name: "Fight",
  emits: ['stop', 'toast'],
  computed: {
    fight(){
      return fightStore.getFight(this.$route.params.stamp)
    }
  },
  data(){
    return{
      i: -1,
      unhid: [false, false, false, false, false, false, false, false, false, false]
    }
  },
  methods: {
    async startFight(){
      this.$emit('stop')
      await this.$refs.fightvid.play()
      setTimeout(this.unhider, 1000)
      setTimeout(() => this.unhider(), 2000)
      setTimeout(() => this.unhider(), 3000)
      setTimeout(() => this.unhider(), 4000)
      setTimeout(() => this.unhider(), 5000)
      setTimeout(() => this.unhider(), 8000)
      setTimeout(() => this.unhider(), 9000)
      setTimeout(() => this.unhider(), 10000)
      setTimeout(() => this.unhider(), 11000)
      setTimeout(() => this.unhider(), 12000)
    },
    finished(){
      this.unhid = [false, false, false, false, false, false, false, false, false, false]
      this.$refs.during.classList.add('hide')
      this.$refs.after.classList.remove('hide')
      this.$emit('play')
    },
    unhider(){
      this.i++
      this.unhid[this.i]=true
      //console.log(elem.classList)
      //elem.classList.remove('hide');
      //elem.classList.add('grower')
    }
  },
  async mounted(){
    if(!this.fight){
      this.$emit('toast', "The replay of that fight doesn't exist. Please try again.")
      this.$router.back()
    } else {
      // "Preloading" videos into cache in case we need them later
      /*if(this.fight.outcome==='W'){
        await fetch("/media/vidw.mp4");
      } else {
        await fetch( "/media/vidl.mp4");
      }*/
    }
  }
}
</script>

<style scoped>
  #fight-container{
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
    margin: 120px auto;
    padding: 0 24px;
    position: relative;
  }

  #fight-box{
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }

  video {
    width: 100%;
    height: auto;
    display: block;
    margin: 0;
    padding: 0;
    position: absolute;
  }

  #border{
    z-index: 100;
    width: 100%;
    position: absolute;
    box-sizing: border-box;
  }

  .overlay-text{
    position:absolute;
    top:0;
    width: 50%;
    box-sizing: border-box;
    z-index:99;
    text-align: right;text-shadow: 0 0 3px #000, 0 0 5px #000;
  }

  .left{
    left: 0;
    padding: 50px 0 0 60px;
  }

  .right{
    right: 0;
    padding: 50px 60px 0 0;
  }

  .info-below{
    display: none;
  }

  /*@media only screen and (max-width: 639px) {
    .overlay-text {
      display: none;
    }
    .info-below {
      display: block;
    }
  }*/

  .hide{
    visibility: hidden;
  }

  .grower {
    transition-duration: 0.5s;
    animation-name: grow;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
  }
  @keyframes grow {
    from {
      transform:scale(1);
    }
    to {
      transform:scale(2);
    }
  }

</style>